<template>
  <v-container id="user-profile" fluid tag="section" class="container-full-size">
    <v-row justify="center">
      <v-col cols="12">
        <base-material-card color="secondary">
          <template #heading>
            <div class="display-2 font-weight-light">
              {{ str['edit_data'] }}
            </div>
          </template>

          <v-form>
            <v-container class="py-0 mt-5">
              <v-row>
                <v-col v-for="input in inputs" :key="input.id" cols="12">
                  <v-text-field v-if="input.type === 'input'" v-model="input.value" :label="input.title" :disabled="input.disabled" />

                  <v-text-field
                    v-if="input.type === 'password'"
                    v-model="input.value"
                    :label="input.title"
                    :append-icon="input.show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="input.show ? 'text' : 'password'"
                    @click:append="input.show = !input.show"
                  />

                  <v-autocomplete
                    v-if="input.type === 'select'"
                    v-model="input.value"
                    :label="input.title"
                    item-text="label"
                    item-value="value"
                    :items="input.items"
                    :no-data-text="str['no_data']"
                  />

                  <v-menu
                    v-if="input.type === 'datepicker'"
                    v-model="datepickerMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field v-model="input.value" :label="input.title" readonly v-bind="attrs" v-on="on" />
                    </template>
                    <v-date-picker v-model="input.value" style="margin: 0" :locale="datepickerLanguage" @change="datepickerMenu = false" />
                  </v-menu>
                </v-col>

                <v-col cols="12" class="text-right mb-5">
                  <v-btn color="success" class="mr-0" @click="save()">
                    {{ str['save'] }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Api from '@/services/Api'
import Utils from '@/services/Utils'

export default {
  data() {
    return {
      str: window.strings,
      datepickerLanguage: window.datepickerLanguage,
      user: Utils.getUser(),
      photo: '',
      datepickerMenu: false,
      inputs: [
        {
          id: 'email',
          title: window.strings['email'],
          value: '',
          type: 'input',
          disabled: true,
        },
        {
          id: 'name',
          title: window.strings['name'],
          value: '',
          type: 'input',
        },
        {
          id: 'mobile_phone',
          title: window.strings['contact'],
          value: '',
          type: 'input',
        },
        {
          id: 'birth_date',
          title: window.strings['birth_date'],
          value: '',
          type: 'datepicker',
        },
        {
          id: 'gender',
          title: window.strings['gender'],
          value: '',
          type: 'select',
          items: Utils.getGenders(),
        },
        {
          id: 'password',
          title: window.strings['password'],
          value: '',
          type: 'password',
          show: false,
        },
        {
          id: 'passwordConfirmation',
          title: window.strings['confirm_password'],
          value: '',
          type: 'password',
          show: false,
        },
      ],
    }
  },
  beforeMount: function () {
    if (!this.user) {
      return false
    }
    this.setData()
  },
  methods: {
    back: function () {
      this.$router.goBack()
    },
    setData: function () {
      for (let i = 0; i < this.inputs.length; i++) {
        this.inputs[i].value = this.user[this.inputs[i].id]
        if (this.inputs[i].id === 'password') {
          this.inputs[i + 1].value = this.user[this.inputs[i].id]
          break
        }
      }
    },
    save: function () {
      const self = this

      if (this.validateFields()) {
        this.$isLoading(true)

        const userData = this.getData()

        Api.updateUser(userData, function (response) {
          self.$isLoading(false)
          response.data.password = userData.password

          if (response.success) {
            Utils.updateAuthorization({
              email: response.data.email,
              password: response.data.password,
            })

            if (Utils.isEmployee(response.data.type)) {
              Api.getPermissions(response.data.db_id, function (permissionsResponse) {
                if (permissionsResponse.success) {
                  response.data.permissions = permissionsResponse.data
                  self.afterSave(response.data)
                } else {
                  self.$alert(permissionsResponse.message, '', 'warning', Utils.getAlertOptions())
                }
              })
            } else {
              self.afterSave(response.data)
            }
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        })
      }
    },
    afterSave: function (data) {
      Utils.updateAuthorization(data)
      const userData = Utils.encodeUser(data)
      const user = Utils.getStorage('user')
      userData.configurations = user.configurations
      Utils.setStorage('user', userData)
      this.back()
    },
    getData: function () {
      const user = {}

      for (let i = 0; i < this.inputs.length; i++) {
        if (this.inputs[i].id !== 'passwordConfirmation') {
          user[this.inputs[i].id] = this.inputs[i].value
        }
      }

      return user
    },
    validateFields: function () {
      let password = ''

      for (let i = 0; i < this.inputs.length; i++) {
        if (!this.inputs[i] && this.inputs[i] !== 0) {
          this.$alert(window.strings['fields_to_be_filled'], '', 'warning', Utils.getAlertOptions())
          return false
        }

        if (this.inputs[i].id === 'password') {
          password = this.inputs[i].value
        }

        if (this.inputs[i].id === 'passwordConfirmation' && this.inputs[i].value !== password) {
          this.$alert(window.strings['password_distinct_error'], '', 'warning', Utils.getAlertOptions())
          return false
        }
      }

      return true
    },
  },
}
</script>
